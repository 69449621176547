<template>
	<div class="main-content">
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Agri Input Progress Detail', 'Agri Input Progress Detail')"
			no-close-on-backdrop
			no-close-on-esc
			size="lg"
			@close="close()"
		>
			<template #content>
				<div>
					<b-row>
						<b-col>
							<h2 class="font-weight-600">
								{{ agriInputFarmerDetail && agriInputFarmerDetail.farmerName ? agriInputFarmerDetail.farmerName : "-" }}
							</h2>
						</b-col>
					</b-row>
					<b-row class="px-16">
						<div class="text-14">
							<span class="text-gray-500">
								{{ `${$t("ID", "ID")}: ${agriInputFarmerDetail && agriInputFarmerDetail.farmerid ? agriInputFarmerDetail.farmerid : "-"}` }}
							</span>
						</div>
					</b-row>
					<b-row class="mt-16">
						<b-col class="col-md-auto text-14">
							<div class="text-gray-500">{{ $t("Status", "Status") }}</div>
							<div class="font-weight-600 text-gray-500">
								{{ agriInputFarmerDetail && agriInputFarmerDetail.status ? agriInputFarmerDetail.status : "-" }}
							</div>
						</b-col>
						<b-col />
						<b-col class="col-md-auto text-14 text-right">
							<div class="text-gray-500">{{ $t("Pickup Point", "Pickup Point") }}</div>
							<div class="font-weight-600 text-gray-500">
								{{ agriInputFarmerDetail && agriInputFarmerDetail.pickupPoint ? agriInputFarmerDetail.pickupPoint : "-" }}
							</div>
						</b-col>
						<b-col class="col-md-auto text-14 text-right">
							<div class="text-gray-500">{{ $t("Address", "Address") }}</div>
							<div class="font-weight-600 text-gray-500">
								{{ agriInputFarmerDetail && agriInputFarmerDetail.address ? agriInputFarmerDetail.address : "-" }}
							</div>
						</b-col>
					</b-row>
					<div class="mt-32">
						<ktv-table
							v-if="agriInputFarmerDetail && agriInputFarmerDetail.data"
							id="farmer-detail"
							:columns="columns"
							:filter="false"
							:is-loading="is.loading"
							:line-numbers="false"
							:rows="filteredData"
							:search-enabled="false"
							:search-on-enter="false"
							:search-placeholder="$t('Search by product name', 'Search by product name')"
							:total-rows="agriInputFarmerDetail.data ? filteredData.length : 0"
							min-height="250px"
							mode="client"
							@on-search="onSearch"
						>
							<template #columns="{ props }">
								{{ $t(props.column.label, props.column.label) }}
							</template>
							<template #rows="{ props }">
								<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + 1 }}</span>
								<span v-else-if="props.column.field === 'quantity'">{{ props.row.quantity | Number }}</span>
								<span v-else-if="props.column.field === 'price'">{{ props.row.price | Rupiah }}</span>
								<span v-else-if="props.column.field === 'totalPrice'">{{ props.row.totalPrice | Rupiah }}</span>
							</template>
						</ktv-table>
					</div>
				</div>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Close', 'Close')" color="light" :loading="is.loading" icon="close" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { filter } from "underscore"
	import { KtvButton, KtvModal, KtvTable } from "@/components"
	import { mapActions, mapState } from "vuex"

	export default {
		name: "PremiumPointsAgriInputProgressDetailFarmerDetail",
		metaInfo: {
			title: "View Premium Points Agri Input Progress",
		},
		components: { KtvButton, KtvModal, KtvTable },
		props: {
			disburseId: {
				default: null,
				type: [Number, String],
			},
			farmerId: {
				default: null,
				type: [Number, String],
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				columns: [
					{
						field: "number",
						label: "Number",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "name",
						label: "Name",
						thClass: "text-left",
						width: "150px",
					},
					{
						field: "unit",
						label: "Unit",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "quantity",
						label: "Quantity",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "price",
						label: "Price (/unit)",
						tdClass: "text-right",
						thClass: "text-right",
						width: "100px",
					},
					{
						field: "totalPrice",
						label: "Total Price",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
				],
				is: {
					loading: false,
					showingModal: false,
				},
				searchText: null,
			}
		},
		computed: {
			...mapState("PREMIUMPOINT", ["agriInputFarmerDetail"]),
			filteredData() {
				return this.searchText
					? filter(this.agriInputFarmerDetail.data, (product) => {
						const name = String(product.name).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return name.indexOf(searchText) > -1
					})
					: this.agriInputFarmerDetail.data
			},
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}

					if (show) {
						this.getAgriInputFarmerDetail({
							disburseId: this.disburseId,
							farmerId: this.farmerId,
						})
					}
				},
			},
		},
		beforeDestroy() {
			this.close()
		},
		methods: {
			...mapActions({
				getAgriInputFarmerDetail: "PREMIUMPOINT/getAgriInputFarmerDetail",
				resetAgriInputFarmerDetail: "PREMIUMPOINT/resetAgriInputFarmerDetail",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}
				this.searchText = null

				this.resetAgriInputFarmerDetail()
				this.$emit("close")
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
		},
	}
</script>
